<template>
  <div v-if="formData">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
        <span class="form-title">Informações Básicas</span>

        <div class="row">
          <ed-form :formData="formData" :handlerSave="salvar">
            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="bind.intParametroId"
              name="intParametroId"
              rules="required"
              label="Código"
              :items="formData.parametros"
              @selected="setFormAtivo"
            />

            <ed-input-editor
              v-if="formDataAtivo"
              :label="formDataAtivo.strNome"
              :info="formDataAtivo.strValor.strInfo"
              :name="formDataAtivo.strChave"
              v-model="formDataAtivo.strValor.strConteudo"
              class="col-12 mt-3"
              extraPlugins="edol"
            />
          </ed-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EdForm, EdInputSelect, EdInputEditor } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {},
  components: {
    EdForm,
    EdInputEditor,
    EdInputSelect,
  },
  inject: [],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        intParametroId: null,
      },
      formData: null,
      formDataAtivo: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    salvar() {
      this.$root.$dialog.loading(true);

      let formData = {};

      formData[this.formDataAtivo.strChave] = Object.assign(this.formDataAtivo, {});

      this.$root.$request.post("Common/Parametro", formData).then((objResponse) => {
        this.$root.$dialog.loading(false);
        if (objResponse.status == 200) {
          this.initialize();
        }
      });
    },

    setFormAtivo(obj) {
      if (obj) {
        this.formDataAtivo = obj;
      }
    },

    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Common/Parametro", {
          strChave: "modeloCodigo",
          boolBuscarSomenteAtivo: true,
        }),
      ]).then(([objConfiguracao]) => {
        this.$root.$dialog.loading(false);

        if (objConfiguracao.status == 200 && objConfiguracao.result) {
          if (!objConfiguracao.result.strValor) {
            objConfiguracao.result.strValor = {};
          }
          this.formData = Object.assign(objConfiguracao.result, {});

          if (!this.bind.intParametroId && this.formData.parametros.length) {
            this.bind.intParametroId = this.formData.parametros[0].intId;
            this.formDataAtivo = this.formData.parametros[0];
          }
        }
      });
    },
  },
};
</script>
