<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>

      <v-tab href="#tab-conteudo">
        Formatar Conteúdo
        <i :class="$utilidade.getIcone('conteudo') + ' fa-2x'"></i>
      </v-tab>

      <v-tab href="#tab-codigo" v-if="intTipoModeloId != 1427">
        Customizar Template
        <i :class="$utilidade.getIcone('template') + ' fa-2x'"></i>
      </v-tab>

     <v-tab href="#tab-imagem">
        Upload de Imagem
        <i :class="$utilidade.getIcone('upload') + ' fa-2x'"></i>
      </v-tab> 
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="strTab == 'tab-codigo' ? null : salvar"
    >
      <cadastro-geral
        :formData="formData"
        v-if="strTab == 'tab-cadastro'"
        :key="'cadastro-' + $root.$session.versao"
      />

      <conteudo
        :formData="formData"
        v-show="strTab == 'tab-conteudo'"
        :key="'conteudo-' + $root.$session.versao"
      />

      <codigo
        :formData="formData"
        v-show="strTab == 'tab-codigo'"
        :key="'codigo-' + $root.$session.versao"
      />
      <ed-upload  v-if="strTab == 'tab-imagem'" :key="'imagem-'+$root.$session.versao"/>

    </ed-form>
  </div>
</template>

<script>
import { EdForm } from "@/components/common/form";
import CadastroGeral from "./partials/geral.vue";
import Conteudo from "./partials/conteudo.vue";
import Codigo from "./partials/codigo.vue";
import EdUpload from "@/components/configuracoes/upload/index";

export default {
  name: "EdModelo",
  props: {
    intTipoModeloId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    intId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    strParametro: {
      type: [String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    Conteudo,
    Codigo,
    EdUpload,
  },
  provide() {
    return {
      bind:this.bind,
      intTipoModeloId:this.intTipoModeloId
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        objTipoModelo: null,
        arrayParametros:[]
      },
      strTab: "tab-cadastro",
      formData: {
        intId: 0,
        intTipoDocumentoId: this.intTipoModeloId,
        strNome: null,
        boolAtivo: 1,
        boolPadrao: 0,
        conteudos: [],
        empresas: [],
        parametros:[]
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Common/Tipo", {
          intId: this.intTipoModeloId,
        }),
        this.intId
          ? this.$root.$request.get("Documento/Modelo", {
              intId: this.intId,
            })
          : null,
        this.strParametro
          ? this.$root.$request.get("Common/Parametro", {
              strChave: this.strParametro,
            })
          : null,
      ]).then(([objTipoModelo, objModelo,objParametro]) => {
        this.$root.$dialog.loading(false);

        if (objTipoModelo && objTipoModelo.status == 200) {
          this.bind.objTipoModelo = objTipoModelo.result;
        }

         if (objParametro && objParametro.status == 200) {
          this.bind.arrayParametros = objParametro.result.parametros;
        }

        this.formatarDados(objModelo);
      });
    },

    formatarDados(objResponse) {
      if (objResponse && objResponse.status == 200) {

        let empresas = objResponse.result.empresas;
        let parametros = objResponse.result.parametros;
        objResponse.result.empresas = [];
        objResponse.result.parametros = [];

        empresas.forEach((objItem) => {
          objResponse.result.empresas.push(objItem.intCadastroGeralEmpresaId);
        });

        parametros.forEach((objItem) => {
          objResponse.result.parametros.push(objItem.intParametroId);
        });

        objResponse.result.conteudos.forEach(element => {
            if(!element.strValor){
              element.strValor = {}
            }
        });

        this.formData = Object.assign(objResponse.result, {});
      }
    },

    salvar() {
      this.$root.$request
        .post("Documento/Modelo", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            if (this.$route.name.indexOf(".edit") == -1) {
              this.$router.push({
                name: this.$route.name.replace('.register','') + ".edit",
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.formatarDados(objResponse);
            }
          }
        });
    },
  },
};
</script>
