<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Cadastro Geral</span>

        <div class="row">
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNome"
            name="strNome"
            label="Nome"
            required
            rules="required"
            :disabled="!formData.boolAtivo"
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            v-model="formData.boolPadrao"
            name="boolPadrao"
            label="Modelo padrão?"
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Modelo ativo?"
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left" v-if="intTipoModeloId != 1427">
        <span class="form-title">Usar esse modelo para as empresas</span>

        <div class="row">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.empresas"
            label="Vincular empresa nesse modelo"
            name="empresas"
            item-text="strNome"
            placeholder="Todas as empresas"
            route="Cadastro/CadastroGeral"
            :filters="{
              intTipoCadastroGeralId: 58,
            }"
            multiple
          />
        </div>

        <span class="form-title" v-if=" bind.arrayParametros.length">Usar esse modelo para os documentos</span>

        <div class="row" v-if=" bind.arrayParametros.length">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.parametros"
            label="Vincular documento nesse modelo"
            name="empresas"
            item-text="strNome"
            placeholder="Todos os documentos"
            :items="bind.arrayParametros"
            multiple
          />
        </div>

       
      </div>
    </div>
  </div>
</template>

<script>
import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputAutoComplete,
    CamposPersonalizado,
  },
  inject:['bind','intTipoModeloId'],
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
