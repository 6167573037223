<template>
  <div>
    <!--EDITAR-->
    <ed-modal v-model="bind.boolExibirModalConteudo" width="50%">
      <div slot="title">Adicionar Novo Conteúdo</div>

      <div class="" slot="content" v-if="formDataConteudo">
        <ed-form
          :formData="formDataConteudo"
          :handlerSave="salvar"
          disableFixedSave
        >
          <div class="row ma-0">
            <ed-input-tipo
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataConteudo.intTipoItemId"
              name="intTipoCampoId"
              label="Item"
              tipo="321"
              rules="required"
              @selected="
                (v) => {
                  formDataConteudo.tipo_item = v;
                }
              "
            />

            <ed-input-text
              v-if="
                formDataConteudo.intTipoItemId == 322
              "
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataConteudo.strValor.strImagem"
              name="strImagem"
              label="URL da Imagem"
            />

            <ed-input-select
              v-if="
                formData.conteudos.length &&
                formDataConteudo.intTipoItemId &&
                [322, 327].indexOf(this.formDataConteudo.intTipoItemId) == -1
              "
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataConteudo.intTipoInserir"
              name="intTipoInserir"
              label="Inserir"
              :items="bind.arrayTipoInserir"
            />

            <ed-input-select
              v-if="
                formData.conteudos.length &&
                formDataConteudo.intTipoItemId &&
                [322, 327].indexOf(this.formDataConteudo.intTipoItemId) == -1
              "
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataConteudo.intOrdem"
              name="intOrdem"
              label="Item"
              itemValue="intOrdem"
              :items="arrayItem"
            />

            <ed-input-select
              v-if="
                formData.conteudos.length &&
                formDataConteudo.intTipoItemId &&
                [322, 327].indexOf(this.formDataConteudo.intTipoItemId) == -1
              "
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataConteudo.strValor.strOrientacao"
              name="strOrientacao"
              label="Orientação da Página"
              :items="bind.arrayOrientacao"
            />

            <ed-input-switch
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataConteudo.strValor.boolQuebraPagina"
              name="boolQuebraPagina"
              label="Inserir em Nova Página"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
        <span class="form-title">Formatar Conteúdo</span>

        <!-- CONTEUDO PADRAO-->
        <div class="row" v-if="intTipoModeloId != 1427 || true">
          <ed-simple-table
            class="col-12 pa-0"
            :config="{ registerName: 'Adicionar Conteúdo' }"
            @register="adicionar"
            disabledSearchRows
          >
            <thead>
              <tr>
                <th width="200">Item</th>
                <th>Conteúdo</th>
                <th>Nova Página</th>
                <th>Orientação</th>
                <th>Imagem</th>
                <th width="150"></th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(objItem, index) in formData.conteudos">
                <tr :key="index">
                  <td class="text-left">
                    <span
                      style="font-size: 9pt; display: block; color: #666666"
                    >
                      <i
                        v-if="objItem.tipo_item.strValor"
                        :class="objItem.tipo_item.strValor.strIcone"
                      ></i>
                      {{ index + 1 }} - {{ objItem.tipo_item.strNome }}
                    </span>
                  </td>
                  <td class="text-left">
                    <template
                      v-if="[322, 327].indexOf(objItem.intTipoItemId) == -1"
                    >
                      <ed-input-text
                        v-if="[323].indexOf(objItem.intTipoItemId) != -1"
                        v-model="objItem.strConteudo"
                        extraPlugins="edol"
                        :key="'editor-s' + index"
                      />
                      <ed-input-text
                        v-if="
                          [322, 327].indexOf(objItem.intTipoItemId) != -1 &&
                          [320, 1322, 1427].indexOf(
                            formData.intTipoDocumentoId
                          ) == -1
                        "
                        v-model="objItem.strConteudo"
                        extraPlugins="edol"
                        :key="'editor-s' + index"
                      />
                      <ed-input-editor
                        v-else-if="
                          [325, 324, 323].indexOf(objItem.intTipoItemId) == -1
                        "
                        v-model="objItem.strConteudo"
                        extraPlugins="edol"
                        :key="'editor-' + index"
                      />
                    </template>
                  </td>
                  <td>
                    <ed-input-switch
                      v-if="
                        [322, 325, 327].indexOf(objItem.intTipoItemId) == -1
                      "
                      v-model="objItem.strValor.boolQuebraPagina"
                      name="boolQuebraPagina"
                    />
                  </td>
                  <td>
                    <i
                      class="fas fa-portrait"
                      title="Retrato"
                      v-if="
                        objItem.strValor &&
                        objItem.strValor.strOrientacao == 'portrait'
                      "
                    ></i>
                    <i
                      class="fas fa-image"
                      title="Paisagem"
                      v-if="
                        objItem.strValor &&
                        objItem.strValor.strOrientacao == 'landscape'
                      "
                    ></i>
                  </td>
                  <td>
                    <img
                      width="100"
                      v-if="objItem.strValor && objItem.strValor.strImagem &&  [322].indexOf(objItem.intTipoItemId) != -1"
                      :src="objItem.strValor.strImagem"
                    />
                  </td>
                  <td>
                    <ed-button
                      :iconLeft="$utilidade.getIcone('deletar')"
                      color="error"
                      title="Deletar"
                      icon
                      onlyIcon
                      @click="remover(index)"
                    />

                    <ed-button
                      v-if="
                        [323, 326, 322].indexOf(objItem.intTipoItemId) != -1
                      "
                      :iconLeft="$utilidade.getIcone('editar')"
                      color="success"
                      title="Editar"
                      icon
                      onlyIcon
                      @click="editar(objItem)"
                    />
                  </td>
                </tr>
              </template>
              <tr v-if="!formData.conteudos.length">
                <td colspan="2" align="center">
                  Nenhum conteúdo foi cadastrado
                </td>
              </tr>
            </tbody>
          </ed-simple-table>
        </div>

        <!-- CONTEUDO CAMPANHA MARKETING-->
        <div class="row" v-if="intTipoModeloId == 1427 && false">
          <div id="bar">
            <h1>Vue Email Editor (Demo)</h1>
            <button v-on:click="saveDesign">Save Design</button>
            <button v-on:click="exportHtml">Export HTML</button>
          </div>

          <EmailEditor
            :appearance="appearance"
            :min-height="minHeight"
            :project-id="projectId"
            :locale="locale"
            :tools="tools"
            :options="options"
            ref="emailEditor"
            v-on:load="editorLoaded"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdForm,
  EdAlert,
  EdButton,
  EdModal,
  EdInputText,
  EdInputSelect,
  EdInputTipo,
  EdInputEditor,
  EdSimpleTable,
  EdInputSwitch,
  EdInputTextArea,
  EdInputFile,
} from "@/components/common/form";
import { EmailEditor } from "vue-email-editor";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  inject: ["intTipoModeloId"],
  components: {
    EdForm,
    EdAlert,
    EdButton,
    EdModal,
    EdInputText,
    EdInputSelect,
    EdInputTipo,
    EdSimpleTable,
    EdInputEditor,
    EmailEditor,
    EdInputSwitch,
    EdInputTextArea,
    EdInputFile,
  },

  mounted() {},
  created() {},
  data() {
    return {
      bind: {
        boolExibirModalConteudo: false,
        arrayTipoInserir: [
          { intId: 1, strNome: "Antes do item" },
          { intId: 2, strNome: "Depois do item" },
        ],
        arrayOrientacao: [
          { intId: "portrait", strNome: "Retrato" },
          { intId: "landscape", strNome: "Paisagem" },
        ],
      },
      formDataConteudo: null,
      //BUILDER

      minHeight: "700px",
      locale: "pt-BR",
      projectId: 0, // replace with your project id
      tools: {
        // disable image tool
        image: {
          enabled: true,
        },
      },
      options: {
        // This is where you pass and absolute URL
        // to the customJS file OR
        // JS code as a string.
        customJS: "console.log(1)",
        features: {
          audit: true,
          textEditor: { cleanPaste: false },
        },
      },
      appearance: {
        theme: "dark",
        panels: {
          tools: {
            dock: "right",
          },
        },
      },
    };
  },
  watch: {},
  computed: {
    arrayItem() {
      let self = this;
      return this.formData.conteudos.filter((objItem, index) => {
        objItem.strNome = index + 1 + " - " + objItem.tipo_item.strNome;
        return (
          [322, 324, 325, 327].indexOf(Number(objItem.intTipoItemId)) == -1 ||
          true
        );
      });
    },
  },
  methods: {
    editorLoaded() {
      var tempDesign = {
        counters: {
          u_row: 1,
          u_column: 1,
          u_content_text: 2,
        },
        body: {
          rows: [
            {
              cells: [1],
              columns: [
                {
                  contents: [
                    {
                      type: "text",
                      values: {
                        containerPadding: "10px",
                        textAlign: "left",
                        lineHeight: "140%",
                        linkStyle: {
                          inherit: true,
                          linkColor: "#0000ee",
                          linkHoverColor: "#0000ee",
                          linkUnderline: true,
                          linkHoverUnderline: true,
                        },
                        _meta: {
                          htmlID: "u_content_text_2",
                          htmlClassNames: "u_content_text",
                        },
                        selectable: true,
                        draggable: true,
                        duplicatable: true,
                        deletable: true,
                        hideable: true,
                        text: '<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 48px; line-height: 67.2px;">This is an example template. Replace the JSON with your own.</span></p>',
                      },
                    },
                  ],
                  values: {
                    _meta: {
                      htmlID: "u_column_1",
                      htmlClassNames: "u_column",
                    },
                    border: {},
                    padding: "0px",
                    backgroundColor: "",
                  },
                },
              ],
              values: {
                displayCondition: null,
                columns: false,
                backgroundColor: "",
                columnsBackgroundColor: "",
                backgroundImage: {
                  url: "",
                  fullWidth: true,
                  repeat: false,
                  center: true,
                  cover: false,
                },
                padding: "0px",
                hideDesktop: false,
                _meta: {
                  htmlID: "u_row_1",
                  htmlClassNames: "u_row",
                },
                selectable: true,
                draggable: true,
                duplicatable: true,
                deletable: true,
                hideable: true,
              },
            },
          ],
          values: {
            textColor: "#000000",
            backgroundColor: "#e7e7e7",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: false,
              center: true,
              cover: false,
            },
            contentWidth: "500px",
            contentAlign: "center",
            fontFamily: {
              label: "Arial",
              value: "arial,helvetica,sans-serif",
            },
            preheaderText: "",
            linkStyle: {
              body: true,
              linkColor: "#e67e23",
              linkHoverColor: "#0000ee",
              linkUnderline: true,
              linkHoverUnderline: true,
              inherit: false,
            },
            _meta: {
              htmlID: "u_body",
              htmlClassNames: "u_body",
            },
          },
        },
        schemaVersion: 6,
      }; // Pass your template JSON here
      this.$refs.emailEditor.editor.loadDesign(tempDesign);
      let blocks = [
        {
          /* Block JSON Object */
        },
        {
          /* Block JSON Object */
        },
        {
          /* Block JSON Object */
        },
      ];

      unlayer.registerProvider("blocks", function (params, done) {
        console.log("blocks provider", params);
        done(blocks);
      });
    },
    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        console.log("saveDesign", design);
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        console.log("exportHtml", data);
      });
    },

    salvar() {
      if (!this.formData.conteudos) {
        this.formData.conteudos = [];
      }

      let intOrdem = this.formDataConteudo.intOrdem;

      if (
        [322, 324, 325, 327].indexOf(this.formDataConteudo.intTipoItemId) != -1
      ) {
        let intIndex = this.formData.conteudos.findIndex(
          (c) =>
            c.intTipoItemId == this.formDataConteudo.intTipoItemId &&
            this.formDataConteudo.intId != c.intId
        );

        if (intIndex != -1) {
          this.$root.$dialog.warning(
            "O " + this.formDataConteudo.tipo_item.strNome + " já foi inserido"
          );
          return;
        }
      }

      if ([322, 327].indexOf(this.formDataConteudo.intTipoItemId) != -1) {
        intOrdem = 0;
      } else if (this.formData.conteudos.length) {
        if (this.formDataConteudo.intTipoInserir == 1) {
          intOrdem--;
        } else {
          intOrdem++;
        }
      } else {
        intOrdem = this.formData.conteudos.length + 1;
      }

      let objItem = {
        intId: this.formDataConteudo.intId,
        intModeloId: this.formDataConteudo.intModeloId,
        intTipoItemId: this.formDataConteudo.intTipoItemId,
        tipo_item: this.formDataConteudo.tipo_item,
        strConteudo: this.formDataConteudo.strConteudo,
        strValor: this.formDataConteudo.strValor,
        intOrdem: intOrdem,
      };

      if (this.formDataConteudo.intId) {
        let intIndex = this.formData.conteudos.findIndex(
          (c) => c.intId == this.formDataConteudo.intId
        );

        this.formData.conteudos[intIndex] = objItem;
      } else {
        this.formData.conteudos.push(objItem);
      }

      this.formDataConteudo = null;
      this.bind.boolExibirModalConteudo = false;

      this.formData.conteudos.sort(function (a, b) {
        if (a.intOrdem > b.intOrdem) {
          return 1;
        }
        if (a.intOrdem < b.intOrdem) {
          return -1;
        }
        return 0;
      });
    },

    adicionar() {
      this.formDataConteudo = {
        intId: 0,
        intTipoItemId: null,
        tipo_item: null,
        strConteudo: null,
        intOrdem: this.formData.conteudos.length
          ? this.formData.conteudos[this.formData.conteudos.length - 1]
              .intOrdem + 1
          : 1,
        intTipoInserir: 2,
        strValor: {
          strOrientacao: "portrait",
        },
      };
      this.bind.boolExibirModalConteudo = true;
    },

    editar(item) {
      if (!item.strValor) {
        item.strValor = {};
      }
      this.formDataConteudo = _.cloneDeep(item);
      this.bind.boolExibirModalConteudo = true;
    },

    remover(intIndex) {
      this.formData.conteudos.splice(intIndex, 1);
    },
  },
};
</script>
